// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,400i,700,700i&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {

}

.hotdeal_wrap {
    padding : 25px;
    .card-deck {
        .card {
            width: 15rem;
            float: left;
            height: 13rem;
            margin:5px;

            .text-break-line-3 {
                font-weight: 400;
                white-space: normal;
                overflow: hidden;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 150%;
            }
        }

        @media(max-width:520px){
            .card {
                width: 100%;
                height: auto;
            }
        }
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA;;AAEA;;AAEA;IACI,cAAc;IACd;QACI;YACI,YAAY;YACZ,WAAW;YACX,aAAa;YACb,UAAU;;YAEV;gBACI,gBAAgB;gBAChB,mBAAmB;gBACnB,gBAAgB;gBAChB,qBAAqB;gBACrB,oBAAoB;gBACpB,qBAAqB;gBACrB,4BAA4B;gBAC5B,iBAAiB;YACrB;QACJ;;QAEA;YACI;gBACI,WAAW;gBACX,YAAY;YAChB;QACJ;IACJ;AACJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap\");\n@import url(\"https://fonts.googleapis.com/css?family=Noto+Serif:400,400i,700,700i&display=swap\");\n@import url(\"https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap\");\n\n.App {\n\n}\n\n.hotdeal_wrap {\n    padding : 25px;\n    .card-deck {\n        .card {\n            width: 15rem;\n            float: left;\n            height: 13rem;\n            margin:5px;\n\n            .text-break-line-3 {\n                font-weight: 400;\n                white-space: normal;\n                overflow: hidden;\n                word-wrap: break-word;\n                display: -webkit-box;\n                -webkit-line-clamp: 3;\n                -webkit-box-orient: vertical;\n                line-height: 150%;\n            }\n        }\n\n        @media(max-width:520px){\n            .card {\n                width: 100%;\n                height: auto;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
